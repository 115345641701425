<template>
    <div class="contenedor-banco">
        <!-- <div class="contenedor-sesiones-banco d-flex justify-content-around align-items-center">
            <div class="boton-sesiones" id="banco" :style="`border-bottom:${marcaBotonBanco}px solid #110133;`" @click="cambiarSesion($event)">Bancos</div>
            <div class="boton-sesiones" id="entrada" :style="`border-bottom:${marcaBotonEntrada}px solid #110133;`" @click="cambiarSesion($event)">Entradas</div>
            <div class="boton-sesiones" id="nuevo" :style="`border-bottom:${marcaBotonNuevo}px solid #110133;`" @click="cambiarSesion($event)">Nuevos</div>
            <div class="boton-sesiones" id="exportar" :style="`border-bottom:${marcaBotonExportar}px solid #110133;`" @click="cambiarSesion($event)">Exportar</div>
        </div> -->
        <div class="space-header"></div>
        <TabsButton 
            :arraySessions="['Nueva entrada','Agregar banco','Exportar']"
            :firstSelect="false"
            :vModel='(data) => tab1 = data'
            :bulReset='bulResetTab1'
            :vResetBul='() => bulResetTab1 = false'
            type="sessions"
        />
        <div class="contenedor-banco-historial">
            <TabsButton
                :arraySessions="['Movimiento','Registro de bancos']"
                :firstSelect="true"
                :vModel='(data) => tabTable = data'
                type="sessionsCards"
            />
            <Table 
                :heightTable="400"
                :dataSourceFilter="movimientosBancosFiltros"
                :actionEdit="(data) => {}"
                :actionDelete="(data) => {
                    bulQuestionDelete = true
                }"
                :boolVisible="tabTable['Movimiento']"
                :stateData="{
                    data: 'movimientosBancos', 
                    dataFilterSet: 'SET_MOVIMIENTOS_BANCOS_FILTROS', 
                }"
                :arrayNameColumns="[
                    {name:'Referencias', reference: 'Referencia', type: 'text', width: 200},
                    {name:'Montos', reference: 'Cantidad', type: 'number', width: 200},
                    {name:'Ent / Sal', reference: 'Tipo_movimiento_banco', type: 'text', width: 200},
                    {name:'Nombres de los Bancos', reference: 'Nombre_banco', type: 'text', width: 200},
                    {name:'Fechas', reference: 'Fecha_creacion', type: 'date', width: 200},
                    {name:'Codigos Contables', reference: 'Cc_banco', type: 'text', width: 200},
                    {name:'Concepto', reference: 'Concepto', type: 'text', width: 200},
                ]"
            />
            <Table 
                :heightTable=400
                :dataSourceFilter="bancosFiltros"
                :actionEdit="(data) => {
                    this.$store.commit('SET_BUL_MODAL', true);bulEditaBanco = true 
                    memoriaBanco = {...data}
                    nuevoNombreBanco = data.Agencia
                }"
                :actionDelete="(data) => {
                    eliminarBanco(data)
                }"
                :boolVisible="tabTable['Registro de bancos']"
                :stateData="{
                    data: 'bancos', 
                    dataFilterSet: 'SET_BANCOS_FILTROS', 
                }"
                :arrayNameColumns="[
                    {name:'Nombre', reference: 'Agencia', type: 'text', width: 200},
                    {name:'Montos', reference: 'Cc_banco', functionData: saldoDelBanco, type: 'number', width: 200},
                    {name:'Codigos contables', reference: 'Cc_banco' , type: 'text', width: 200},
                ]"
            />               
        </div>
        <FormCustom
            typeContainer="window"
            title="Agregar Banco"
            textSecButton="Agregar"
            :actionSecButton="(data)=>agregarBanco(data, true)"
            widthWindowForm="400px"
            :exitForm='() => bulResetTab1 = true'
            :reiniciarDataForm="reiniciarFormAgregarBanco"
            :bulWindow="tab1['Agregar banco']"
            :limitInputsInSection="7"
            :objectForm="[
                {
                    type: 'text',
                    label: 'Agencia',
                    name: 'agencia',
                    
                },
                {
                    type: 'text',
                    label: 'Teléfonos de la agencia',
                    name: 'telefono',
                    
                },   
                {
                    type: 'text',
                    label: 'Contacto',
                    name: 'contacto',
                    
                },   
                {
                    type: 'number',
                    label: 'Comisión',
                    name: 'comision',                    
                    
                },
                {
                    type: 'text',
                    label: 'Direccion',
                    name: 'direccion',
                    
                },   
                {
                    type: 'text',
                    label: 'Url del sitio web',
                    name: 'url',
                    
                },                                                     
            ]"
        />
        <ModalCustom
            :bulModal="tab1['Exportar']"
            :exitModal='() => bulResetTab1 = true'
            title="Exportar"
        >
            <div class="container-all-export">
                <div>
                    <TabsButton
                        :arraySessions="['Movimientos bancos','Registro de bancos']"
                        :firstSelect="true"
                        :vModel='(data) => tab3 = data'
                        type="sessionsCards"
                    />
                    <FilterExport
                        :boolVisible="tab3['Movimientos bancos']"
                        :dataSourceFilter="movimientosBancosFiltros"
                        :stateData="{
                            data: 'movimientosBancos', 
                            dataFilterSet: 'SET_MOVIENTOS_BANCOS_FILTROS', 
                        }"
                        :arrayNameColumns="[
                            {name:'Referencias', reference: 'Referencia', type: 'text'},
                            {name:'Montos', reference: 'Cantidad', type: 'number'},
                            {name:'Ent / Sal', reference: 'Tipo_movimiento_banco', type: 'text'},
                            {name:'Nombre de bancos', reference: 'Nombre_banco', type: 'text'},
                            {name:'Fechas', reference: 'Fecha_creacion', type: 'date'},
                            {name:'Codigos contables', reference: 'Cc_banco', type: 'text'},
                            {name:'Concepto', reference: 'Concepto', type: 'text'},
                        ]"
                    />
                    <FilterExport
                        :boolVisible="tab3['Registro de bancos']"
                        :dataSourceFilter="bancosFiltros"
                        :stateData="{
                            data: 'bancos', 
                            dataFilterSet: 'SET_BANCOS_FILTROS', 
                        }"
                        :arrayNameColumns="[
                            {name:'Nombre', reference: 'Nombre_banco', type: 'text', width: 200},
                            {name:'Montos', reference: 'Cc_banco', functionData: saldoDeLaCaja, type: 'number', width: 200},
                            {name:'Codigos contables', reference: 'Cc_banco' , type: 'text', width: 200},
                        ]"
                    />
                    <div class="container-form-export">
                        <FormCustom
                            typeContainer="space"
                            typeStyle="column"
                            :objectForm="
                                [
                                    {
                                        type: 'text',
                                        label: 'Nombre de archivo',
                                        widthComplete: true,
                                        width: `50%`,
                                        vmodel: (values) => response(values)
                                    },
                                    {
                                        type: 'select',
                                        label: 'Formato',
                                        widthComplete: true,
                                        width: `50%`,
                                        array: arregloFormatos,
                                        vmodel: (values) => response(values)
                                    },
                                ]
                            "
                        >
                            <template v-slot:contentBeforeButton>
                                <p class="p-before-buttons">Se va a exportar lo anteriormente filtrado en el apartado de caja, puedes guardar tambien estos informes</p>
                            </template>
                        </FormCustom>
                    </div>
                </div>
            </div>
        </ModalCustom>  
        
        <!-- interfaz nueva entrada -->
        <FormCustom
            typeContainer="window"
            title="Nueva entrada"
            textSecButton="Agregar"
            :actionSecButton="(data)=>{
                agregarMovimiento(data)
            }"
            widthWindowForm="500px"
            :exitForm='() => bulResetTab1 = true'
            :reiniciarDataForm="reiniciarFormNuevaEntrada"
            :bulWindow="tab1['Nueva entrada']"
            :limitInputsInSection="4"
            :objectForm="[
                {
                    type:'managementData',
                    array: bancos,
                    label: 'Banco seleccionado',
                    name: 'Banco_seleccionado',
                    subData1: 'Cc_banco',
                    subData2: 'Agencia',
                    subData3: true,
                    functionEdit: (value) => editarCaja(value),
                    functionAdd: (value) => agregarNuevaCaja(value.Nombre_caja),
                    functionDelete: (value) => eliminarCaja(value),
                },          
                {
                    type: 'number',
                    label: 'Monto',
                    name: 'Monto',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'date',
                    label: 'Fecha',
                    name: 'Fecha',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'text',
                    label: 'Referencia',
                    name: 'Referencia',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'text',
                    width: `40%`,
                    label: 'Concepto',
                    name: 'Concepto',
                    widthComplete: true,
                    vmodel: (values) => response(values)
                },
                {
                    type: 'radio',
                    width: `250px`,
                    name: 'Tipo',
                    style: 'radio',
                    widthComplete: true,
                    array: ['Entrada','Salida'],
                    vmodel: (values) => response(values)
                },
                {
                    type: 'checkbox',
                    widthComplete: true,
                    name: 'Info_plantilla',
                    array: ['Mantener informacion como plantilla'],
                    vmodel: (values) => response(values)
                },
            ]"
        />
        <!-- nueva interfaz contra movimientos -->
        <FormCustom
            typeContainer="window"
            :title="`Contra Movimiento ${nuevoMovimientoMemo.Tipo === 'Entrada' ? 'Salida' : 'Entrada'}`"
            textSecButton="Agregar"
            :actionSecButton="(data)=>{
                agregarNuevoContraMovimiento(data)
            }"
            widthWindowForm="500px"
            :exitForm='() => bulContraMovimientoForm = false'
            :bulWindow="bulContraMovimientoForm"
            :dataRefForm="Object.entries(this.$refs)"
            :objectForm="[
                {
                    type: 'custom',
                    widthComplete: false,
                    name: 'inputMontoTotal'
                },
                {
                    type:'managementData',
                    array: codigosContablesFiltros,
                    label: 'Código contable',
                    name: 'Codigo_contable',
                    subData1: 'Codigo',
                    subData2: 'Referencia',
                    subData3: true,
                    functionAdd: (value) => agregarCodigoContable(value),
                    functionDelete: (value) => {eliminarCodigoContable(value)},
                    functionEdit: (value) => editarCodigoContable(value)
                },             
                {
                    type: 'number',
                    label: 'Monto',
                    name: 'Monto',
                    vmodel: (values) => response(values)
                },
                {
                    type: 'checkbox',
                    widthComplete: true,
                    name: 'Seguir_agregando',
                    array: ['Sumar Contramovimiento'],
                    vmodel: (values) => response(values)
                },
                {
                    type: 'custom',
                    widthComplete: false,
                    name: 'tableContraMovimientosInform'
                }          
            ]"
        >
            <template v-slot:inputMontoTotal>
                <div style="display:flex; justify-content:center; flex-direction:column; align-items:center;" ref="INPUTMONTOTOTAL" class="container-input">
                    <label for="Monto_total" class="label">Monto Total</label>
                    <input type="text" disabled class="input" :value="montoTotalMovimientos" style="background:green; color:white; width:max-content; padding:0;text-align:center;" name="Monto_total" >
                </div>
            </template>
            <template v-slot:tableContraMovimientosInform>
                <div class="contenedor-banco-historial" style="margin: 0px 16px 16px 16px;">
                    <label for="" class="label">Contra Movimientos agregados</label>
                    <Table
                        :heightTable="150"
                        :dataSourceFilter="contraMovimientosMemo"
                        :actionEdit="(data) => { 
                            bulModalEditContramovimiento = true
                            contraMovimientoSeleccionado = data
                        }"
                        :actionDelete="(data) => borrarContraMovimiento(data)"
                        :boolVisible="bulContraMovimientoForm"
                        :stateData="{
                            data: 'movimientosBancos', 
                            dataFilterSet: 'SET_MOVIMIENTOS_BANCOS_FILTROS', 
                        } "                   
                        :arrayNameColumns="[
                            {name:'Monto',              reference: 'Monto',             type: 'number', width: 200},
                            {name:'Nombre', reference: 'Nombre',          type: 'text',   width: 200},
                        ]"
                    />
                </div>
            </template>
        </FormCustom>
        <ModalCustom
            :bulModal="bulModalEditContramovimiento"
            :exitModal='() => bulModalEditContramovimiento = false'
            :widthCard="'300px'"
        >
            <FormCustom
                textSecButton="Editar"
                typeStyle="column"
                :isOneButton="true"
                :objectForm="[
                    {
                        label: 'Monto',
                        type: 'number',
                        name: 'Monto',
                        valueDefault: contraMovimientoSeleccionado.Monto,
                        required: true,
                    }
                ]"
                :buttons="[,
                    {
                        text: 'Editar',
                        style: {
                            marginTop: '-40px',
                            width: '150px',
                        }
                    }
                ]"
                :actionSecButton="(data)=>{
                    editarContraMovimiento(contraMovimientoSeleccionado, data.Monto)
                    bulModalEditContramovimiento = false
                }"
            />
        </ModalCustom>       
             
        <!-- FILTROS TABLA ANTERIOR -->
        <!-- <div class="contenedor-banco-historial d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 1">
            <div class="caja-filtro d-flex justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`" @click="abrirFiltros">Filtros<div class="simboloIzquierda" :style="`transform: rotateZ(${izquierdaDesplegable}deg);`"></div><div class="simboloDerecha" :style="`transform: rotateZ(${derechaDesplegable}deg);`"></div></div>
            <div class="caja-filtros d-flex justify-content-around align-items-center flex-row flex-wrap" :style="`height:${alturaFiltro}px; padding-top:${paddingTop}px;`">
                <div class="col-2 margin-izquierda">
                    <label for="desdeBanco" class="label">Desde</label>
                    <input type="date" id="desdeBanco" class="input" v-model="desde" :max="hasta" @change="actualizarFiltros"/>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="hastaBanco" class="label">Hasta</label>
                    <input type="date" id="hastaBanco" class="input" v-model="hasta" :min="desde" @change="actualizarFiltros" />
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="agenciaBanco" class="label">Agencia</label>
                    <select name="" id="agenciaBanco" class="input" v-model="agencia" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="agencias in agenciass" v-bind:key="agencias.id" v-bind:value="agencias">{{agencias}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="contactoBanco" class="label">Contacto</label>
                    <select name="" id="contactoBanco" class="input" v-model="contacto" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="contactos in contactoss" v-bind:key="contactos.id" v-bind:value="contactos">{{contactos}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="direccionBanco" class="label">Dirección</label>
                    <select name="" id="direccionBanco" class="input" v-model="direccion" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="direcciones in direccioness" v-bind:key="direcciones.id" v-bind:value="direcciones">{{direcciones}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="montoMaximo" class="label">Monto máximo</label>
                    <input type="text" id="montoMaximo" class="input" v-model="montoMax" @change="actualizarFiltros"/>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="montoMinimo" class="label">Monto mínimo</label>
                    <input type="text" id="montoMinimo" class="input" v-model="montoMin" @change="actualizarFiltros"/>
                </div>
            </div>
            <div class="caja-enunciado"></div>
        </div> -->

        <!-- TABLA ANTERIOR DE BANCOS -->
        <!-- <div class="contenedor-banco-historial d-flex justify-content-center align-items-center flex-column" v-if="bulSesiones === 2">
            <div class="col-6 margin-izquierda">
                <label for="banco" class="label">Banco seleccionado</label>
                <select name="" id="banco" class="input2" v-model="estado" @keyup="bancoS">
                        <option value="" ></option>
                        <option v-for="bancoSeleccionado in bancoSeleccionados" v-bind:key="bancoSeleccionado.id" v-bind:value="bancoSeleccionado">{{bancoSeleccionado}}</option>
                </select>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="monto" class="label">Monto</label>
                <input type="text" id="monto" class="input" v-model="monto"/>
            </div>
            <div class="col-6 d-flex flex-row justify-content-center align-items-center">
                <div class="entrada d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox d-flex justify-content-center align-items-center" @click="selectCheckbox($event)"><div v-if="caja1 === true" class="caja"></div></div>
                    <p class="texto-check">Entrada</p>
                </div>
                <div class="salida d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox2 d-flex justify-content-center align-items-center" @click="selectCheckbox2($event)"><div v-if="caja2 === true" class="caja2"></div></div>
                    <p class="texto-check">Salida</p>
                </div>
            </div>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center">Agregar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
            </div>
        </div>
        <div class="contenedor-banco-historial2 d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 3">
            <div class="col-6 margin-izquierda">
                <label for="agencia" class="label">Agencia</label>
                <input type="text" id="agencia" class="input3" v-model="agenciaB"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="codigoContableBanco" class="label">Código contable</label>
                <input type="text" id="codigoContableBanco" class="input3" v-model="codigoContableBanco"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="telefonosA" class="label">Teléfonos de la agencia</label>
                <input type="text" id="telefonosA" class="input3" v-model="telefonosA"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="contactoB" class="label">Contacto</label>
                <input type="text" id="contactoB" class="input3" v-model="contactoB"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="comisionBanco" class="label">Comisión</label>
                <input type="text" id="comisionBanco" class="input3" v-model="comisionBanco"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="direccionBanco" class="label">Direccion</label>
                <input type="text" id="direccionBanco" class="input3" v-model="direccionBanco"/>
            </div>
            <div class="col-6 margin-izquierda">
                <label for="urlWeb" class="label">Url del sitio web</label>
                <input type="text" id="urlWeb" class="input3" v-model="urlWeb"/>
            </div>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center">Crear</button>
                <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
            </div>
        </div>
        -->

        <!-- Anterior Formulario EXPORTAR -->
        <!-- <div class="contenedor-banco-historial3 d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 4" ref="FORMEXPORT">
            <div class="col-6">
                <label for="filtradoCaja" class="label3">Filtrado caja</label>
                <input type="text" id="filtradoCaja" class="input3" v-model="filtradoCaja" />
            </div>
            <div class="col-12 d-flex flex-nowrap justify-content-around align-items-center">
                <div class="cuadro-filtro-seleccionado" v-for="seleccion in seleccionFiltro" v-bind:key="seleccion.id" v-bind:value="seleccion">{{seleccion}}</div>
            </div>
            <div class="col-6">
                <label for="formato" class="label3">Formato</label>
                <input type="text" id="formato" class="input3" v-model="formato" />
            </div>
            <p class="exportar-informacion">Se va a exportar lo anteriormente filtrado en el apartado de banco, puedes guardar tambien estos informes</p>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center">Descargar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
            </div>
        </div> -->
        <!-- Interfaz modal seguro eliminar banco -->
        <ModalCustom
            :bulModal="bulQuestionDelete"
            :exitModal='() => bulQuestionDelete = false'
            widthCard="600px"
            title="¿Seguro que quieres eliminar este movimiento conjunto con sus contramovimientos?"
        >
            <div class="contenedor-seguro-boton2 d-flex flex-column flex-sm-row justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="()=>{
                    eliminarMovimientosCaja()
                    bulQuestionDelete = false
                }">
                    Eliminar
                </button>
                <button class="button-exportar d-flex justify-content-center align-items-center" @click="bulQuestionDelete = false" >Cancelar</button>
            </div>
        </ModalCustom>
        <ModalCustom
            :bulModal="bulEditaBanco"
            :exitModal='() => bulEditaBanco = false'
            :widthCard="'300px'"
        >
            <FormCustom
                textSecButton="Editar"
                typeStyle="column"
                :isOneButton="true"
                :objectForm="[
                    {
                        label: 'Banco Seleccionado',
                        type: 'text',
                        name: 'nuevaAgencia',
                        valueDefault: memoriaBanco.Agencia,
                        required: true,
                    }
                ]"
                :buttons="[,
                    {
                        text: 'Editar',
                        style: {
                            marginTop: '-40px',
                            width: '150px',
                        }
                    }
                ]"
                :actionSecButton="(data)=>{
                    editarBanco({...memoriaBanco, nuevaAgencia: data.nuevaAgencia})
                    bulEditaBanco = false
                }"
            />
        </ModalCustom>                
    </div>
</template>

<script>
    import axios from 'axios'
    import Footer from "../../../components/Footer.vue"
    import FormCustom from "../../../components/FormCustom.vue"
    import {mapState, mapActions} from "vuex"
    import messagesToUser from '../../../helpers/messagesToUser.js'
    import Table from "../../../components/Table.vue"
    import TabsButton from "../../../components/TabsButton.vue"
    import ModalCustom from "../../../components/ModalComponent.vue"
    import FilterExport from '../../../components/FilterExport.vue'    

    export default {
        name: "Banco",
        components: {
            TabsButton,
            Table,
            ModalCustom,
            FormCustom,
            Footer,
            FilterExport,            
        },
        data(){
            return{
                //tabs
                bulResetTab1: false,
                tab1: {},
                tabTable: {},
                tab3: {},
                bulQuestionDelete: false,

                //fondo
                alturaFondoDegradado: `calc(100% + 290px)`,

                //control sesiones
                bulSesiones: 1,
                marcaBotonBanco: 4,
                marcaBotonEntrada: 0,
                marcaBotonNuevo: 0,
                marcaBotonExportar: 0,

                //deplegable filtro y variables filtro
                izquierdaDesplegable: 50,
                derechaDesplegable: -50,
                medidaBorde:10,
                alturaFiltro: 0,
                paddingTop: 0,
                desde:'',
                hasta:'',
                agencia: '',
                agenciass: this.agenciass = [],
                contacto: '',
                contactoss: this.contactoss = [],
                direccion: '',
                direccioness: this.direccioness = [],
                montoMax: '',
                montoMin: '',

                //interfax de entrada al banco
                banco1: false,
                banco2:false,
                monto: '',
                bancoS: '',
                bancoSeleccionados: this.bancoSeleccionados = [],

                //interfaz de exportar
                filtradoCaja: '',
                seleccionFiltro: this.seleccionFiltro = [],
                formato: '',

                //interfaz nuevo banco
                agenciaB: '',
                codigoContableBanco: '',
                telefonosA: '',
                contactoB: '',
                comisionBanco: '',
                direccionBanco: '',
                urlWeb: '',

                //contraBanco
                bulContraBanco: false,
                duracionError: 820,

                //interfaz de agregar nuevo movimiento
                arregloTodosLosMovimientos: this.arregloTodosLosMovimientos = [],  
                banco1: false,
                banco2: false,
                banco6: false,
                bancoMovimiento: false,
                bancoSeleccionada1: '',
                bancoSeleccionada2: '',
                bancoSeleccionadoss: this.bancoSeleccionadoss = [],
                bancoSeleccionadossFiltro: this.bancoSeleccionadossFiltro = [],
                bulBancoSobre: false,
                bulContraBanco: false,
                codigoBanco: '',
                codigoDeBancoMemoria: '',
                conceptoC: '',
                fechaC: '',
                fechaVVenta: '',
                montoC: '',
                montoFinal: 0,
                referenciaC: '', 
                ultimoCodigoBancoCreado: '',
                
                //interfaz de contra movimiento
                bulCambioColor: false,
                bulCodigosMemoria: false,
                bulCodigoSobre: false,
                codigoContableBanco1: '',
                codigoContableBanco2: '',
                conceptoContra: '',
                informacionContraMemoria: this.informacionContraMemoria = [],
                montoContra: '',
                relojCodigo: '',
                tipoColor: 'codigo',
                tipoDeContraMovimiento: '',
                tipoRelacionBanco: '',    
                
                
                //interfaz editar movimiento
                arregloTodosLosMovimientos2: this.arregloTodosLosMovimientos2 = [], 
                banco7: false,
                banco8: false,
                bancoSeleccionada3: '',
                bancoSeleccionada4: '',                
                bulBancoSobre2: false,
                bulMovimientoEditar: false,
                codigoDeBancoMemoria2: '',
                conceptoC2: '',
                fechaC2: '',
                montoC2: '',
                referenciaC2: '',

                //interfaz de cambio de codigo, y banco
                memoriaBanco: {},
                nuevoNombreBanco: "",
                bulEditaBanco: false,

                bulModalEditContramovimiento: false,
                bulContraMovimientoForm: false,
                nuevoMovimientoMemo: {},
                contraMovimientosMemo: [],
                montoTotalMovimientos: 0,
                codigoDe: {
                    '1.1.1.02.': 'banco',
                    '1.1.1.01.': 'caja',
                },
                contraMovimientoSeleccionado: {
                    Codigo_contable: {
                        color: "",
                        Codigo: "",
                        Referencia: ""
                    },
                    Monto: 0,
                    Seguir_agregando: {
                        "Sumar Contramovimiento": false
                    },
                    Nombre: ""
                },
                reiniciarFormNuevaEntrada: false,
                reiniciarFormAgregarBanco: false,
                reiniciarFormExportar: false,
            }
        },
        methods:{
            //actions vuex
            ...mapActions([
                "activeMessageCorrect",
                "activeMessageError",
                "crearBanco",
                "crearCodigoContable",
                "editarBancoVuex",
                "editarCodigoContableBancoVuex",
                "eliminarBancoVuex",
                "eliminarCodigoContableBancoVuex",
                "excessLimitCharacters",
                "requestBancos",
                "requestCajas",
                "requestCodigosContables",
                "requestMovimientosBancos",
                "resetStatusApiBanco",
                "resetStatusApiCodigoC",
                "setUltimoCodigoContableBanco",
                "someFieldEmpty",
            ]),

            response(data){
                console.log(data)
            },

            changeAndBackBoolWithDelay(element, boolFirstTime, time){
                this[element] = !boolFirstTime
                const delay = setTimeout(()=>{
                    this[element] = boolFirstTime
                    clearTimeout(delay)
                }, time)
            },            

            // nuevo agregar movimiento
            async agregarMovimiento(data){
                const {Banco_seleccionado, Concepto, Fecha, Monto, Referencia, Tipo} = data
                const {Agencia = "", Cc_banco = "", color} = Banco_seleccionado
                const inputsVacios = await this.someFieldEmpty({
                    fields:{ Cc_banco, Agencia, Concepto, Fecha, Monto, Referencia }
                })

                if(Banco_seleccionado === "" || Cc_banco === ""){
                    this.activeMessageError("Por favor seleccione una caja.")
                    return
                }
                if(inputsVacios) {
                    this.activeMessageError("Rellena todas las celdas.")
                    return
                }
                if(Monto <= 0){
                    this.activeMessageError("El monto no puede ser 0 o menos.")
                    return
                }
                if(Tipo === "Salida"){
                    const saldoDelBanco = this.saldoDelBanco(Cc_banco)
                    const saldoInsuficiente = saldoDelBanco - Monto < 0
                    
                    if(saldoInsuficiente){
                        this.activeMessageError("El banco no cuenta con saldo suficiente.")
                        return
                    }
                }
                
                this.montoTotalMovimientos += Monto
                this.nuevoMovimientoMemo = data
                this.bulContraMovimientoForm = true
            },

            async agregarNuevoContraMovimiento(data){
                const {Monto_total, Monto, Contramovimientos, Seguir_agregando, Codigo_contable} = data
                const {Codigo = "", Referencia = ""} = Codigo_contable
                const inputsVacios = await this.someFieldEmpty({fields:{Referencia, Codigo}})
                const Tipo = this.nuevoMovimientoMemo.Tipo === "Entrada" ? "Salida" : "Entrada"
                const codigoIdentificatorio = Codigo.substring(0,9)

                if(inputsVacios) {
                    this.activeMessageError("Rellena todas las celdas.")
                    return
                }
                if(Monto <= 0){
                    this.activeMessageError("El monto no puede ser 0 o menos.")
                    return
                }
                if(Monto_total - Monto < 0){
                    this.activeMessageError("El monto total no puede ser menos de 0.")
                    return
                }
                if(Codigo === this.nuevoMovimientoMemo. Banco_seleccionado.Cc_banco){
                    this.activeMessageError("El contramovimiento no puede ser el mismo banco que el movimiento principal.")
                    return
                }
                if(Tipo === "Salida" && this.codigoDe[codigoIdentificatorio] === "banco"){
                    const saldoDelBanco = this.saldoDelBanco(Codigo)
                    const saldoInsuficiente = saldoDelBanco - Monto < 0
                    
                    if(saldoInsuficiente){
                        this.activeMessageError("La caja no cuenta con saldo suficiente.")
                        return
                    }
                }
                
                this.contraMovimientosMemo.push({...data, Nombre: Referencia})
                this.montoTotalMovimientos -= Monto
                window.scrollTo(0,0)

                if(Seguir_agregando['Sumar Contramovimiento'] === false){
                    this.crearMovimientos({dataMovimiento: this.nuevoMovimientoMemo, dataArrayContraMovimientos: this.contraMovimientosMemo})
                    
                    // parametros: elemento, buleano en su estado inicial, tiempo de delay
                    this.changeAndBackBoolWithDelay("reiniciarFormNuevaEntrada", this.reiniciarFormNuevaEntrada, 100)                    
                    this.bulContraMovimientoForm = false
                }
            },

            //crear un nuevo movimiento caja paso final

            async crearMovimientos(data){
                const {dataMovimiento, dataArrayContraMovimientos} = data
                
                //ver que el monto ya haya sido llevado a 0
                if(this.montoTotalMovimientos !== 0){
                    this.activeMessageError('lleva el saldo a 0 agregando los contramovimientos que veas pertinentes')
                    this.bulContraMovimientoForm = false;
                    setTimeout(() => {
                        this.bulContraMovimientoForm = true;
                    }, this.duracionError);
                    return
                }

                this.arregloTodosLosMovimientos = []

                this.arregloTodosLosMovimientos.push({
                    cantidad: dataMovimiento.Monto, 
                    fecha: dataMovimiento.Fecha,
                    movimiento: dataMovimiento.Tipo, 
                    nombre: dataMovimiento.Banco_seleccionado.Agencia, 
                    codigoContable: dataMovimiento.Banco_seleccionado.Cc_banco, 
                    concepto: dataMovimiento.Concepto, 
                    tipo: "banco", 
                    referencia: dataMovimiento.Referencia, 
                    contra: false
                })

                for(let i = 0; i < dataArrayContraMovimientos.length; i++){
                    const contraMovimiento = dataArrayContraMovimientos[i]
                    const {Codigo, Referencia} = contraMovimiento.Codigo_contable
                    let tipoContraMovimiento = this.codigoDe[Codigo.substring(0,9)] || "otro"

                    this.arregloTodosLosMovimientos.push({
                        cantidad: contraMovimiento.Monto, 
                        fecha: dataMovimiento.Fecha, 
                        movimiento: dataMovimiento.Tipo === "Salida" ? "Entrada" : "Salida", 
                        nombre: Referencia, 
                        codigoContable: Codigo,
                        concepto: dataMovimiento.Concepto,
                        tipo: tipoContraMovimiento, 
                        referencia: dataMovimiento.Referencia, 
                        contra: true
                    })
                }

                //creando los movimientos
                let movimientos = await axios.post(`${this.server}/information/crearMovimientoCaja`, {
                    arregloInformacion: this.arregloTodosLosMovimientos,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })

                if(movimientos.data.message === 'movimientos creados'){
                    //caja creada con exito
                    this.activeMessageCorrect('movimientos creados')

                    //resetiando monto comprobacion
                    let contador = this.informacionContraMemoria.length

                    for(let i = 0; i < contador; i++){
                        this.montoFinal = this.montoFinal - Number(this.informacionContraMemoria[0].monto)
                        this.informacionContraMemoria.splice(0,1)
                    }

                    //reseiando todos los campos si es necesario
                    if(this.caja6 === false){
                        this.cajaSeleccionada1 = ''
                        this.cajaSeleccionada2 = ''
                        this.montoC = ''
                        this.fechaC = ''
                        this.referenciaC = ''
                        this.conceptoC = ''
                        this.caja1 = false
                        this.caja2 = false
                        this.montoContra = ''
                        this.codigoContableCaja1 = ''
                        this.codigoContableCaja2 = ''
                        this.conceptoContra = ''
                    }
                    this.bulContraMovimientoForm = false;
                    setTimeout(() => {
                        this.bulContraMovimientoForm = false;
                    }, this.duracionError);
                }
                if(movimientos.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulContraMovimientoForm = false;
                    setTimeout(() => {
                        this.bulContraMovimientoForm = true;
                    }, this.duracionError);
                    return
                }
            },

            //eliminar contra movimiento

            borrarContraMovimiento(contramovimientoABorrar){
                const nuevoContraMovimientosMemo = this.contraMovimientosMemo
                    .filter((contraMovimiento) => {
                        const {Codigo} = contraMovimiento.Codigo_contable
                        const CodigoDeContraABorrar = contramovimientoABorrar.Codigo_contable.Codigo
                        return Codigo !== CodigoDeContraABorrar
                    })

                this.contraMovimientosMemo = nuevoContraMovimientosMemo
                this.montoTotalMovimientos += contramovimientoABorrar.Monto
                return
            },
            editarContraMovimiento(contraMovimientoAEditar, montoNuevo){
                const nuevoContraMovimientosMemo = this.contraMovimientosMemo
                    .map((contraMovimiento)=>{
                        const {Codigo} = contraMovimiento.Codigo_contable
                        const CodigoDeContraAEditar = contraMovimientoAEditar.Codigo_contable.Codigo

                        if(Codigo === CodigoDeContraAEditar){
                            return {...contraMovimiento, Monto: montoNuevo}
                        }else{
                            return contraMovimiento
                        }
                    })

                this.contraMovimientosMemo = nuevoContraMovimientosMemo
                this.montoTotalMovimientos += contraMovimientoAEditar.Monto - montoNuevo
                return
            },            

            //saber el saldo que tiene una caja 

            saldoDelBanco(codigoSeleccionado){
                //no se pede sacar dinero que no esta agregado en un banco existente
                let cantidadDeDineroBanco = 0
                let filterMovimientoBancoSalida = this.movimientosBancos.filter(bancosMovimiento => {
                    return bancosMovimiento.Cc_banco === codigoSeleccionado && bancosMovimiento.Tipo_movimiento_banco.toLowerCase() === 'salida'
                })
                let filterMovimientoBancoEntrada = this.movimientosBancos.filter(bancosMovimiento => {
                    return bancosMovimiento.Cc_banco === codigoSeleccionado && bancosMovimiento.Tipo_movimiento_banco.toLowerCase() === 'entrada'
                })

                for(let i = 0; i < filterMovimientoBancoSalida.length; i++){
                    cantidadDeDineroBanco = cantidadDeDineroBanco - filterMovimientoBancoSalida[i].Cantidad
                }
                for(let i = 0; i < filterMovimientoBancoEntrada.length; i++){
                    cantidadDeDineroBanco = cantidadDeDineroBanco + filterMovimientoBancoEntrada[i].Cantidad
                }

                return cantidadDeDineroBanco
            },            

            //funcion encargada del checkbox

            selectCheckbox(e){
                if(e.target.className === 'checkbox d-flex justify-content-center align-items-center'){
                    if(this.caja1 === false){
                        this.caja1 = true
                        this.caja2 = false
                    }
                }
            },

            //funcion encargada del checkbox2

            selectCheckbox2(e){
                if(e.target.className === 'checkbox2 d-flex justify-content-center align-items-center'){
                    if(this.caja2 === false){
                        this.caja2 = true
                        this.caja1 = false
                    }
                }
            },

            //cambiar sesion de banco

            cambiarSesion(e){
                if(e.target.id === 'banco'){
                    this.bulSesiones = 1
                    this.marcaBotonBanco = 4
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 0
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
                if(e.target.id === 'entrada'){
                    this.bulSesiones = 2
                    this.marcaBotonBanco = 0
                    this.marcaBotonEntrada = 4
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 0
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
                if(e.target.id === 'nuevo'){
                    this.bulSesiones = 3
                    this.marcaBotonBanco = 0
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 4
                    this.marcaBotonExportar = 0
                    this.alturaFondoDegradado = `calc(100% + 690px)`
                }
                if(e.target.id === 'exportar'){
                    this.bulSesiones = 4
                    this.marcaBotonBanco = 0
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 4
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
            },
        
            // funciones para banco, agregar, editar y borrar
            async agregarBanco (data, bul) {
                const {agencia, telefono, contacto, comision, direccion, url} = data

                //ver que los campos no esten vacios
                const camposVacios = await this.someFieldEmpty({fields: data})

                //campos con limites en caracteres
                const excedenLimite = await this.excessLimitCharacters({fields:{agencia, contacto, direccion}, limit: 30}) 

                if(camposVacios || excedenLimite) return

                //agregando el codigo contable
                const codigoContableData = {
                    codigo: this.ultimoCodigoContableBanco,
                    nombre: agencia,
                    tipoCodigo: 'codigo',
                    relacionCodigo: 'banco'
                }

                const status = await this.crearCodigoContable(codigoContableData)

                if(status === "ok"){
                    //creando el banco
                    const dataNuevoBanco = {
                        agencia,
                        cc_banco: this.ultimoCodigoContableBanco,
                        telefono,
                        contacto,
                        comision,
                        direccion,
                        url,
                    }

                    await this.crearBanco(dataNuevoBanco)

                    // reiniciamos inputs
                    // parametros: elemento, buleano en su estado inicial, tiempo de delay
                    this.changeAndBackBoolWithDelay("reiniciarFormAgregarBanco", this.reiniciarFormAgregarBanco, 100)

                    if(bul){
                        this.bulResetTab1 = true
                    }

                }else{
                    const message = this.statusApiCodigoC.message
                    this.activeMessageError(messagesToUser[message])
                }

            },


            async editarBanco(data){
                const {Agencia, Codigo_banco, Id_banco, Cc_banco, nuevaAgencia} = data

                //ver que los campos no esten vacios
                const camposVacios = await this.someFieldEmpty({fields: {nuevaAgencia}})

                //campos con limites en caracteres
                const excedenLimite = await this.excessLimitCharacters({fields:{nuevaAgencia}, limit: 30}) 

                if(camposVacios || excedenLimite) return

                //consulta de la apirest
                let object = {
                    codigo: Codigo_banco,
                    nombre: nuevaAgencia,
                    nombreMemoria: Agencia,
                    codigoMemoria: Cc_banco,
                    idCodigo: Id_banco,
                }
                await this.editarBancoVuex(object)
            },
            async eliminarBanco(data){
                //id,nombre,cc
                const {Id_banco, Agencia, Cc_banco} = data
                this.resetStatusApiBanco()
                //eliminando banco
                let bancoData = {
                    idCodigo: Id_banco,
                    codigo: Cc_banco,
                    nombre: Agencia,
                }

                await this.eliminarBancoVuex(bancoData)
            },

            //funciones para codigo contable
            //funcion para agregar un nuevo codigo contable

            async agregarCodigoContable(data){
                const {codigoContable, codigoContableNombre, tipoCodigoColor} = data
                //calculando puntos de codigo
                let textoLista = codigoContable.split('');
                let puntosEncontrados = textoLista.filter((puntoH) => {return puntoH === '.'})

                //control de errores
                const camposVacios = await this.someFieldEmpty({fields: {codigoContable, codigoContableNombre}, cbError: () => this.activeMessageError('porfavor rellena los campos para crear un nuevo codigo contable')})

                const codigoContableNombreExcedido = await this.excessLimitCharacters({fields:{codigoContableNombre}, limit:30, cbError: () => this.activeMessageError('nombre codigo no puede exceder los 30 caracteres')})
                
                let masDeUnPunto 
                if(tipoCodigoColor === 'encabezado' && puntosEncontrados.length > 0){
                    this.activeMessageError('Los encabezados se deben crear con un solo punto')
                    masDeUnPunto = true
                }

                if(camposVacios || codigoContableNombreExcedido || masDeUnPunto) {
                    this.bulContraBanco = false
                    setTimeout(() => {
                        this.bulContraBanco = true;
                    }, this.duracionError)                    
                    return
                }

                //creando el codigo contable

                let codigoContableData = {
                    codigo: codigoContable,
                    nombre: codigoContableNombre,
                    tipoCodigo: tipoCodigoColor,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2'),
                    relacionCodigo: 'nada'
                }

                await this.crearCodigoContable(codigoContableData)
                
                this.bulContraBanco = false;
                setTimeout(() => {
                    this.bulContraBanco = true;
                }, this.duracionError)      
            },
            //editar un codigo contable

            async editarCodigoContable(data){
                const {codigoContableNuevo, codigoContableNombreNuevo, codigoContableMemoria, codigoContableNombreMemoria, tipoCodigoColorNuevo, codigoContableRelacionMemoria, idCodigo} = data
                //calculando puntos de codigo
                let textoLista = codigoContableNuevo.split('');
                let puntosEncontrados = textoLista.filter((puntoH) => {return puntoH === '.'})

                //revisando que no se creen encabezados desordenados
                if(tipoCodigoColorNuevo === 'encabezado' && puntosEncontrados.length > 0){
                    this.activeMessageError('Los encabezados se deben crear con un solo punto')
                    this.bulEditaCodigo = false;
                    setTimeout(() => {
                        this.bulEditaCodigo = true;
                    }, this.duracionError);
                    return
                }

                //consulta de la apirest
                let codigoContableRes = await axios.post(`${this.server}/information/editarCodigoContable`, {
                    codigo: codigoContableNuevo,
                    nombre: codigoContableNombreNuevo,
                    nombreMemoria: codigoContableNombreMemoria,
                    relacionMemoria: codigoContableRelacionMemoria,
                    tipoCodigo: tipoCodigoColorNuevo,
                    idCodigo: idCodigo,
                    memoria: codigoContableMemoria,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })

                //control de respuestas
                if(codigoContableRes.data.message === 'Codigo contable editada'){
                    //password cambiada
                    this.activeMessageCorrect('Codigo editado con exito')
                    this.bulEditaCodigo = false
                    setTimeout(() => {
                        this.bulContraBanco = true;
                    }, this.duracionError);
                }
                if(codigoContableRes.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulEditaCodigo = false;
                    setTimeout(() => {
                        this.bulEditaCodigo = true;
                    }, this.duracionError);
                }
                if(codigoContableRes.data.message === 'Ya existe este codigo'){
                    //el codigo esta en uso
                    this.activeMessageError('Este codigo ya esta en uso')
                    this.bulEditaCodigo = false;
                    setTimeout(() => {
                        this.bulEditaCodigo = true;
                    }, this.duracionError);
                }
            },
            //eliminar un codigo contable

            async eliminarCodigoContable(id,codigo,nombre,tipo){
                //eliminando el codigo contable
                let codigoContableResponse = await axios.post(`${this.server}/information/eliminarCodigoContable`, {
                    idCodigo: id,
                    codigo: codigo,
                    nombre: nombre,
                    tipo: tipo,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })
                
                if(codigoContableResponse.data.message === 'Codigo eliminada'){
                    //password cambiada
                }
                if(codigoContableResponse.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulContraBanco = false
                    setTimeout(() => {
                        this.bulContraBanco = true
                    }, this.duracionError)                 
                    return
                }
                if(codigoContableResponse.data.message === "Error movimientos existentes"){
                    //se espiro la url
                    this.activeMessageError('No puedes eliminar un codigo que tenga movimientos relacionados')
                    this.bulContraBanco = false
                    setTimeout(() => {
                        this.bulContraBanco = true
                    }, this.duracionError)                     
                    return
                }
            },

            //Movimientos bancos

            //crear movimiento banco
            // async crearMovimientos(data){
            //     //variables con respecto a entrada o salida de los movimientos
            //     //movimiento
            //     let tipo1
            //     //opuesto contra movimiento
            //     let tipo2

            //     if(this.banco1 === true){
            //         tipo1 = "entrada"
            //         tipo2 = "salida"
            //     }
            //     if(this.banco2 === true){
            //         tipo1 = "salida"
            //         tipo2 = "entrada"
            //     }

            //     //ver que el monto ya haya sido llevado a 0
            //     if((this.montoC - this.montoFinal) !== 0){
            //         this.activeMessageError('lleva el saldo a 0 agregando los contramoviminetos que veas pertinentes')
            //         this.bulContraBanco = false;
            //         setTimeout(() => {
            //             this.bulContraBanco = true;
            //         }, this.duracionError);
            //         return
            //     }

            //     this.arregloTodosLosMovimientos = []

            //     for(let i = 0; i < (this.informacionContraMemoria.length + 1); i++){
            //         if(i === 0){
            //             this.arregloTodosLosMovimientos.push({cantidad: this.montoC, fecha: this.fechaC, movimiento: tipo1, nombre: this.bancoSeleccionada2, codigoContable: this.codigoContableMemoria, concepto: this.conceptoC, tipo: "banco", referencia: this.referenciaC, contra: false})
            //         }else{
            //             this.arregloTodosLosMovimientos.push({cantidad: this.informacionContraMemoria[i-1].monto, fecha: this.fechaC, movimiento: tipo2, nombre: this.informacionContraMemoria[i-1].nombre, codigoContable: this.informacionContraMemoria[i-1].codigo, concepto: this.informacionContraMemoria[i-1].concepto,tipo: this.informacionContraMemoria[i-1].tipo, referencia: this.referenciaC, contra: true})
            //         }

            //     }

            //     //creando los movimientos
            //     let movimientosBancoResponse = await axios.post(`${this.server}/information/crearMovimientoBanco`, {
            //         arregloInformacion: this.arregloTodosLosMovimientos,
            //         token: localStorage.getItem('token'),
            //         tokenGestion: localStorage.getItem('token2')
            //     })

            //     if(movimientosBancoResponse.data.message === 'movimientos creados'){
            //         //caja creada con exito
            //         this.activeMessageCorrect('movimientos creados')

            //         //resetiando monto comprobacion
            //         let contador = this.informacionContraMemoria.length

            //         for(let i = 0; i < contador; i++){
            //             this.montoFinal = this.montoFinal - Number(this.informacionContraMemoria[0].monto)
            //             this.informacionContraMemoria.splice(0,1)
            //         }

            //         //reseiando todos los campos si es necesario
            //         if(this.banco6 === false){
            //             this.agencia = ''
            //             this.montoC = ''
            //             this.fechaC = ''
            //             this.referenciaC = ''
            //             this.conceptoC = ''
            //             this.banco1 = false
            //             this.banco2 = false
            //             this.montoContra = ''
            //             this.conceptoContra = ''
            //         }
            //         this.bulContraBanco = false;
            //         setTimeout(() => {
            //             this.bulContraBanco = false;
            //         }, this.duracionError);
            //     }
            //     if(movimientosBancoResponse.data.message === "Error en validación"){
            //         //se espiro la url
            //         this.activeMessageError('tiempo de sesion excedido')
            //         this.bulContraBanco = false;
            //         setTimeout(() => {
            //             this.bulContraBanco = true;
            //         }, this.duracionError);
            //         return
            //     }
            // },

            //editar un  movimiento caja

            async editarMovimientos(){
                //variables con respecto a entrada o salida de los movimientos
                    //movimiento
                let tipo1
                    //opuesto contra movimiento
                let tipo2

                if(this.banco7 === true){
                    tipo1 = "entrada"
                    tipo2 = "salida"
                }
                if(this.banco8 === true){
                    tipo1 = "salida"
                    tipo2 = "entrada"
                }

                //ver que el monto ya haya sido llevado a 0
                if((this.montoC2 - this.montoFinal2) !== 0){
                    this.activeMessageError('lleva el saldo a 0 agregando los contramoviminetos que veas pertinentes')
                    this.bulContraMovimientoEditar1 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar1 = true;
                    }, this.duracionError);
                    return
                }

                this.arregloTodosLosMovimientos2 = []

                for(let i = 0; i < (this.informacionContraMemoria2.length + 1); i++){
                    if(i === 0){
                        this.arregloTodosLosMovimientos2.push({cantidad: this.montoC2, fecha: this.fechaC2, movimiento: tipo1, nombre: this.bancoSeleccionada4, codigoContable: this.codigoDeBancoMemoria2, concepto: this.conceptoC2, tipo: "banco", referencia: this.referenciaC2, contra: false, idEdicion: this.idInicialesEdicion[0].id})
                    }else{
                        this.arregloTodosLosMovimientos2.push({cantidad: this.informacionContraMemoria2[i-1].monto, fecha: this.fechaC2, movimiento: tipo2, nombre: this.informacionContraMemoria2[i-1].nombre, codigoContable: this.informacionContraMemoria2[i-1].codigo, concepto: this.informacionContraMemoria2[i-1].concepto,tipo: this.informacionContraMemoria2[i-1].tipo, referencia: this.referenciaC2, contra: true, idEdicion: ''})
                    }
                }

                //editando los movimientos
                let editarMovimientosBancoResponse = await axios.post(`${this.server}/information/editarMovimientoBanco`, {
                    idsEdicion: this.idInicialesEdicion,
                    idPadre: this.relacionIdPadre,
                    arregloInformacion2: this.arregloTodosLosMovimientos2,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                })

                if(editarMovimientosBancoResponse.data.message === 'Movimiento editado'){
                    //movimiento banco creado con exito
                    this.activeMessageCorrect('movimientos editados')
                    
                    this.bancoSeleccionada3 = ''
                    this.bancoSeleccionada4 = ''
                    this.montoC2 = ''
                    this.fechaC2 = ''
                    this.referenciaC2 = ''
                    this.conceptoC2 = ''
                    this.banco7 = false
                    this.banco8 = false
                    this.montoContra2 = ''
                    this.codigoContableBanco3 = ''
                    this.codigoContableBanco4 = ''
                    this.conceptoContra2 = ''
                    let contador = this.informacionContraMemoria2.length
                    this.idInicialesEdicion = [];

                    for(let i = 0; i < contador; i++){
                        this.montoFinal2 = this.montoFinal2 - Number(this.informacionContraMemoria2[0].monto)
                        this.informacionContraMemoria2.splice(0,1)
                    }
                    this.bulContraMovimientoEditar1 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar1 = false;
                    }, this.duracionError);
                }
                if(editarMovimientosCajaResponse.data.message === "Error en validación"){
                    //se espiro la url
                    this.activeMessageError('tiempo de sesion excedido')
                    this.bulContraMovimientoEditar1 = false;
                    setTimeout(() => {
                        this.bulContraMovimientoEditar1 = true;
                    }, this.duracionError);
                    return
                }
            },         
        },
        async created(){
            await this.requestBancos()
            await this.requestMovimientosBancos()
            await this.requestCodigosContables()
            await this.setUltimoCodigoContableBanco()
        },
        computed:{
        ...mapState([
            "bancos",
            "bancosFiltros",
            "movimientosBancos",
            "movimientosBancosFiltros",
            "codigosContablesFiltros",
            "server", 
            "statusApiBanco",
            "statusApiCodigoC",
            "token", 
            "ultimoCodigoContableBanco", 
        ]),
        }
    }
</script>

<style scoped>
    .space-header{
        height: 80px;
        width: 100%;
    }
    .fondo-degradado-recover{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        z-index: -10;
    }
    .contenedor-sesiones-banco{
        width: 100%;
        margin-top:80px;
        height: 100px;
    }
    .boton-sesiones{
        width: 15%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 26px;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        padding-top:15px ;
        padding-bottom:15px ;
        border-radius: 16px;
        cursor: pointer;
        border-bottom:0px solid #110133;
        transition: 0.2s ease-in-out;
    }
    .contenedor-banco{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .entrada,.salida{
        width: 40%;
    }
    .texto-check{
        margin-top: 6px;
    }
    .contenedor-botones{
        margin-top:50px;
        width: 100%;
    }
    .button-exportar{
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        width: 30%;
        margin-left: 0%;
    }
    .contenedor-banco-historial,.contenedor-banco-historial2,.contenedor-banco-historial3{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 20px;
        height: auto;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
        position: relative;
    }
    .contenedor-banco-historial2{
        height: 1000px;
        padding-top: 100px;
    }
    .contenedor-banco-historial3{
        padding-top: 100px;
    }
    .caja-filtro{
        background:rgba(196,196,196,0.25);
        width: 100px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        left: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .caja-filtros{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 100px;
        margin-top:40px;
        border-radius:0px 10px 10px 10px;
        transition: all 1s;
        overflow: hidden;
    }
    .simboloIzquierda{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: 7.5px;
        transition: all 1s;
    }
    .simboloDerecha{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: -3.5px;
        transition: all 1s;
    }
    .margin-izquierda{
        margin-left:4%;
    }
    .input,.input2,.input3{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 30px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
    }
    .input2{
        margin-bottom: 50px;
    }
    .input3{
        margin-bottom: 40px;
    }
    .label{
        font-family: Rubik;
        font-weight:bold ;
    }
    .caja-enunciado{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 50px;
        border-radius:10px;
        transition: all 1s;
        margin-top:15px;
    }
    .checkbox,.checkbox2{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
    }
    .caja,.caja2{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
    }
    .exportar-informacion{
        width: 60%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
    }
    .cuadro-filtro-seleccionado{
        background: linear-gradient(153.24deg, rgba(0, 145, 142, 0.4) 9.04%, rgba(255, 255, 255, 0) 100%);
        width: 130px;
        padding:5px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        border-radius:10px ;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2), inset 0 0 5px white;
        margin-top:10px;
        margin-bottom: 25px;
    }
    
    .contenedor-seguro-boton2{
        height: auto;
        margin-top: 100px;
        width:100%;
        transition: all 1s;
        border-radius:0px 0px 16px 16px;
    }
</style>